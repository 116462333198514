// import React from "react";
import React, { useEffect, useState } from "react";
import { getActiveTestimonialList } from "services/testimonials.service";
import { Col, Row } from "react-bootstrap";
import "./Home.css";

import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { NavLink, Link } from "react-router-dom";
import { subscribeNewsletter } from "services/newsletter.service";
import { blogDataByTitle } from "services/blog.service";
import { IMAGEURL } from "global";
import { dynamicTraction } from "services/configuration.service";
import Banner from "../Banner/Banner";
import ContactUs from "../Contact-Us/ContactUs";

function Home() {
  const [users, setUsers] = useState([]);
  const [newsEventsData, setNewsEventData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorClass, setErrorClass] = useState("");
  const [dynamicTractions, setDynamicTractions] = useState([]);
  var obj = {
    title1: "News",
    title2: "Events",
  };

  useEffect(() => {
    getActiveTestimonialList()
      .then((response) => response.json())
      .then((data) => {
        //setUsers(data.Result);
      });
    blogDataByTitle(obj).then((response) => {
      console.log(response);
      //setNewsEventData(response.data.Result);
    });
    dynamicTraction().then((response) => {
      setDynamicTractions([]);
    });
  }, []);

  let setting2 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true, // Set to true to loop through the images infinitely
    autoplay: true,
    autoplaySpeed: 3000, // Change to 3000 milliseconds for a 3-second interval
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings = {
    // dots: true, // Show dots navigation
    infinite: true, // Loop through the images infinitely
    speed: 500, // Animation speed in milliseconds
    slidesToShow: 3, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: true, // Autoplay the slider
    autoplaySpeed: 3000, // Autoplay interval in milliseconds (3 seconds)
    pauseOnHover: true, // Pause autoplay when hovering over the slider
    arrows: false, // Show navigation arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();

  const valueDigit = (value) => {
    console.log(value);
    switch (value) {
      case value > 999 && value <= 99999:
        return `Math.floor(${value}/1000)K`;
      case value > 99999 && value <= 999999:
        return `Math.floor(${value}/100000)L`;
      case value > 999999 && value <= 9999999:
        return `Math.floor(${value}/1000000)Cr.`;
      default:
        return `${value}`;
    }
  };

  return (
    <>
      <section className="pg-home">
        {/* <Header /> */}
        {/* Banner Section */}
        <Banner />

        {/* About Section */}
        <section className="ev-about-sec">
          <div className="flexi">
            <div className="container">
              <Row>
                <Col lg={6}>
                  <div className="grid-50 about-left_sec">
                    <div className="inner_wrapper">
                      <p>
                        We are Amar Financial & Allied Insurance Co. Ltd.,
                        India’s leading private Health Insurance company.
                        Headquartered in Chennai, we began our operations in
                        2006 as India's first standalone Health Insurance
                        provider. Today, with over 14,000 Network Hospitals ,
                        850+ branch offices spread across the country, over 6.40
                        lakh agents and over Rs. 30,300 crore in paid claims, we
                        are truly the health insurers to the nation.
                      </p>
                      <br></br>
                      <Link to="about">
                        <button className="main-cto-second">
                          About Us
                          <img
                            src="images/Home/white_arrow.svg"
                            alt="imgNotFound"
                          />
                        </button>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="about-right_sec">
                    <div className="about_img">
                      <iframe
                        // src="https://www.youtube.com/embed/dQx2TvhFwMc"
                        src="https://www.youtube.com/embed/YunElOZsxcI"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="flexi">
                <div className="grid-100 stats_sec">
                  <ul>
                    {Object.keys(dynamicTractions).map((key, index) => {
                      const value = Object.values(dynamicTractions)[index];
                      console.log(valueDigit(value));
                      return (
                        <li>
                          <div className="counter-box">
                            <a>
                              <span className="count">{valueDigit(value)}</span>
                              {key !== "Edupreneurs" &&
                              key !== "Application" ? (
                                <span style={{ color: "red" }}>+</span>
                              ) : (
                                ""
                              )}
                            </a>
                            <p>{key}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Offering Section */}
        <section className="ev-offering_sec">
          <div className="flexi">
            <div className="container">
              <Row>
                <Col>
                  <div className="">
                    <h2 className="main_head">Our Offerings</h2>
                    <p className="para">
                      We are Amar Financial & Allied Insurance Co. Ltd., India’s
                      leading private Health Insurance company. Headquartered in
                      Chennai, we began our operations in 2006 as India's first
                      standalone Health Insurance provider. Today, with over
                      14,000 Network Hospitals , 850+ branch offices spread
                      across the country, over 6.40 lakh agents and over Rs.
                      30,300 crore in paid claims, we are truly the health
                      insurers to the nation.{" "}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <div className="first">
                    <div className="offering_box">
                      <Link to="health-insurance">
                        {/* <a href="http://localhost:3000/health-insurance"> */}
                        <span>
                          <img
                            src="images/Home/icons_Capital.svg"
                            alt="imgNotFound"
                          />
                        </span>
                        <h3>Health Policy</h3>
                        <p className="para" style={{ display: "none" }}>
                          We are Amar Financial & Allied Insurance Co. Ltd.,
                          India’s leading private Health Insurance company.
                          Headquartered in Chennai, India's first standalone
                          Health Insurance provider. Today, with over 14,000
                          Network Hospitals , 850+ branch offices spread across
                          the country, over 6.40 lakh agents and over Rs. 30,300
                          crore in paid claims, we are truly the health insurers
                          to the nation.
                        </p>
                        {/* </a> */}
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="grid-25 second">
                    <div className="offering_box">
                      <a href="http://localhost:3000/health-insurance">
                        <span>
                          <img
                            src="images/Home/icons_customers.svg"
                            alt="imgNotFound"
                          />
                        </span>
                        <h3>Life Insurance</h3>
                        <p className="para" style={{ display: "none" }}>
                          We are Amar Financial & Allied Insurance Co. Ltd.,
                          India’s leading private Health Insurance company.
                          Headquartered in Chennai, India's first standalone
                          Health Insurance provider. Today, with over 14,000
                          Network Hospitals , 850+ branch offices spread across
                          the country, over 6.40 lakh agents and over Rs. 30,300
                          crore in paid claims, we are truly the health insurers
                          to the nation.
                        </p>
                      </a>
                    </div>
                  </div>
                </Col>

                <Col lg={3}>
                  <div className="grid-25 third">
                    <div className="offering_box">
                      <a href="http://localhost:3000/health-insurance">
                        <span>
                          <img
                            src="images/Home/icons_mentor.svg"
                            alt="imgNotFound"
                          />
                        </span>
                        <h3>All types of Insuarence</h3>
                        <p className="para" style={{ display: "none" }}>
                          We are Amar Financial & Allied Insurance Co. Ltd.,
                          India’s leading private Health Insurance company.
                          Headquartered in Chennai, India's first standalone
                          Health Insurance provider. Today, with over 14,000
                          Network Hospitals , 850+ branch offices spread across
                          the country, over 6.40 lakh agents and over Rs. 30,300
                          crore in paid claims, we are truly the health insurers
                          to the nation.
                        </p>
                      </a>
                    </div>
                  </div>
                </Col>

                <Col lg={3}>
                  <div className="grid-25 fourth">
                    <div className="offering_box">
                      <a href="http://localhost:3000/health-insurance">
                        <span>
                          <img
                            src="images/Home/icons_ecosystem.svg"
                            alt="imgNotFound"
                          />
                        </span>
                        <h3>Mentorship</h3>
                        <p className="para" style={{ display: "none" }}>
                          We are Amar Financial & Allied Insurance Co. Ltd.,
                          India’s leading private Health Insurance company.
                          Headquartered in Chennai, India's first standalone
                          Health Insurance provider. Today, with over 14,000
                          Network Hospitals , 850+ branch offices spread across
                          the country, over 6.40 lakh agents and over Rs. 30,300
                          crore in paid claims, we are truly the health insurers
                          to the nation.
                        </p>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        {/* News and Events */}
        <section className="ev-news_sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h2 className="main-head">News & Events</h2>
                <div className="inner_wrapper">
                  {/* News Slider */}
                  <Slider {...setting2}>
                    <div className="item1">
                      <img
                        src="https://d28c6jni2fmamz.cloudfront.net/health_for_me_e1c67c326c.svg"
                        alt="No Image Found"
                        className="new-img"
                      />
                      <a className="news_head">
                        <span className="date">28-Oct-2023</span>
                        Plans for Individual{" "}
                      </a>
                      <p>
                        Health policies designed to protect you against medical
                        expenses.
                      </p>
                    </div>{" "}
                    <div className="item1">
                      <img
                        src="https://d28c6jni2fmamz.cloudfront.net/plans_for_family_a2e26b45b7.svg"
                        alt="No Image Found"
                        className="new-img"
                      />
                      <a className="news_head">
                        <span className="date">28-Oct-2023</span>
                        Plans for Family
                      </a>
                      <p>
                        Health policies specially designed to cover your entire
                        family.
                      </p>
                    </div>{" "}
                    <div className="item1">
                      <img
                        src="https://d28c6jni2fmamz.cloudfront.net/plans_for_senior_cirizens_6f28f9300b.svg"
                        alt="No Image Found"
                        className="new-img"
                      />
                      <a className="news_head">
                        <span className="date">28-Oct-2023</span>
                        Plans for Senior Citizens{" "}
                      </a>
                      <p>
                        Specially crafted policy for senior citizens to stay
                        financially protected.
                      </p>
                    </div>{" "}
                    <div className="item1">
                      <img
                        src="https://d28c6jni2fmamz.cloudfront.net/plans_for_corporates_b53cf92325.svg"
                        alt="No Image Found"
                        className="new-img"
                      />
                      <a className="news_head">
                        <span className="date">28-Oct-2023</span>
                        Plans for Corporates{" "}
                      </a>
                      <p>
                        Specially designed plans that suit your corporate
                        requirements.
                      </p>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Trusted by Us section */}
        <section className="bg-white py-12">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-8">
              Trusted by Us
            </h2>
            <Slider {...settings}>
              <div>
                <img
                  src="/images/company1.png" // Add your image source
                  alt="Company 1"
                  className="h-12 mx-auto"
                />
              </div>
              <div>
                <img
                  src="/images/company2.png" // Add your image source
                  alt="Company 2"
                  className="h-12 mx-auto"
                />
              </div>
              <div>
                <img
                  src="/images/company3.png" // Add your image source
                  alt="Company 3"
                  className="h-12 mx-auto"
                />
              </div>
              <div>
                <img
                  src="/images/company4.png" // Add your image source
                  alt="Company 4"
                  className="h-12 mx-auto"
                />
              </div>
              {/* Add more images as needed */}
            </Slider>
          </div>
        </section>
        {/* Testimonials */}
        {/* {/* <> */}
        {/* <section className="ev-testimonials_sec">
            <div className="flexi">
              <div className="container">
                <div className="flexi">
                  <h2 className="main_head">What People Say About Us?</h2>

                  <div className="inner_wrapper">  */}
        {/* Swiper carousel */}
        {/* <Slider {...setting2}>
                      <div className="item2">
                        <img
                          src="https://d28c6jni2fmamz.cloudfront.net/plans_for_corporates_b53cf92325.svg"
                          alt=""
                          className="testi-img"
                        />
                        <h5>
                          Vikas Rawat 1 <span>C.E.O</span>
                        </h5>
                        <p>
                          Specially designed plans that suit your corporate
                          requirements.
                        </p>
                      </div>
                      <div className="item2">
                        <img
                          src="https://d28c6jni2fmamz.cloudfront.net/plans_for_corporates_b53cf92325.svg"
                          alt=""
                          className="testi-img"
                        />
                        <h5>
                          Vikas Rawat 2<span>C.E.O</span>
                        </h5>
                        <p>
                          Specially designed plans that suit your corporate
                          requirements.
                        </p>
                      </div>
                      <div className="item2">
                        <img
                          src="https://d28c6jni2fmamz.cloudfront.net/plans_for_corporates_b53cf92325.svg"
                          alt=""
                          className="testi-img"
                        />
                        <h5>
                          Vikas Rawat 3<span>C.E.O</span>
                        </h5>
                        <p>
                          Specially designed plans that suit your corporate
                          requirements.
                        </p>
                      </div>
                      <div className="item2">
                        <img
                          src="https://d28c6jni2fmamz.cloudfront.net/plans_for_corporates_b53cf92325.svg"
                          alt=""
                          className="testi-img"
                        />
                        <h5>
                          Vikas Rawat 4<span>C.E.O</span>
                        </h5>
                        <p>
                          Specially designed plans that suit your corporate
                          requirements.
                        </p>
                      </div>
                      <div className="item2">
                        <img
                          src="https://d28c6jni2fmamz.cloudfront.net/plans_for_corporates_b53cf92325.svg"
                          alt=""
                          className="testi-img"
                        />
                        <h5>
                          Vikas Rawat 5<span>C.E.O</span>
                        </h5>
                        <p>
                          Specially designed plans that suit your corporate
                          requirements.
                        </p>
                      </div>
                    </Slider> */}
        {/* </div>
                </div>
              </div>
            </div>
          </section>
        </> */}
        {/* Contact us  */}

        {/* About Section */}
        <>
          <section className="ev-about-sec">
            <div className="flexi">
              <div className="container">
                <div className="flexi">
                  <ContactUs />
                </div>
              </div>
            </div>
          </section>
        </>
      </section>
    </>
  );
}

export default Home;
