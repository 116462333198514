import React from "react";
import { Col, Row } from "react-bootstrap";
import "./About.css";
import { NavLink, Link } from "react-router-dom";
import Banner from "../Banner/Banner";

function About() {
  return (
    <>
      <section className="pg-about">
        {/* Banner */}
        {/* <Banner /> */}

        {/* About Content */}
        <section className="ev-about-sec">
          <div className="flexi">
            <div className="container">
              <Row>
                <Col lg={12}>
                  <div className="grid-50 about-left_sec">
                    <div className="inner_wrapper">
                      <p>
                        Amar Financial & Allied Insurance Co. Ltd
                        <br />
                        <br />
                        We are Amar Financial & Allied Insurance Co. Ltd.,
                        India’s leading private Health Insurance company.
                        Headquartered in Chennai, we began our operations in
                        2006 as India's first standalone Health Insurance
                        provider. Today, with over 14,000 Network Hospitals ,
                        850+ branch offices spread across the country, over 6.40
                        lakh agents and over Rs. 30,300 crore in paid claims, we
                        are truly the health insurers to the nation.
                        <br />
                        <br />
                        With over 15,000 employees, we offer innovative services
                        and products in health, personal accident and overseas &
                        domestic travel insurance aligned with the needs of the
                        Indian Market. With meticulously crafted insurance
                        policies, we continue to address the unique health needs
                        of people. Having covered more than 17 crore lives since
                        inception, we are scaling up our services to ensure that
                        more lives are covered under Health Insurance.<br></br>
                        Being the Health Insurance Specialist, we ensure that
                        timely assistance is provided during health emergencies.
                        Our dedicated in-house claim settlement team has an
                        excellent track record of settling more than Rs. Four
                        crores as claim amount every hour.<br></br> We offer
                        products for every market sector, from individuals to
                        families and corporates. As a new age company, we
                        operate directly through various channels, including
                        digital, aggregators, brokers, etc., to cater to the
                        needs of all sections of society. We are also a leader
                        in the field of bancassurance and have long-standing
                        partnerships with several banks.<br></br> Along with
                        offering a broad selection of specialist products and
                        Health Insurance, we also offer free Telehealth
                        consultations with our expert team of in-house doctors,
                        along with a free second medical opinion if necessary.
                        We have completed over 8 lakh consultations which focus
                        on primary care, specialist’s referral, secondary
                        opinions and decision support.<br></br> Our most
                        significant element is our customer service and the
                        experience we provide. We have a 24x7 multilingual call
                        centre, which is dedicated to claims assistance.
                        Additionally, customers can benefit from hassle-free
                        internal claim resolution without any TPA (third party
                        administrator).<br></br> In this digitally driven world,
                        we have adapted to the current trends by digitally
                        transforming ourselves with innovative technologies to
                        guarantee the quality of service, ease of access and
                        seamless user experience for all our customers.
                      </p>
                    </div>
                  </div>
                </Col>
                {/* <Col lg={6}>
                  <div className="grid-50 about-right_sec">
                    <div className="about_img">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/W_Lc_HHJ5kw"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>
                    </div>
                  </div>
                </Col> */}
              </Row>
              <div className="flexi"></div>
            </div>
          </div>
        </section>
        {/* Philosophy */}
        <section className="ev-philosophy_sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h3>Our Philosophy</h3>
                <p>
                  We are the founder's first ecosystem with a vast experience
                  with EdTech startups and education entrepreneurs. Our entire
                  focus lays on providing the best education sector focused
                  insights, experience, on ground relatability, market access
                  opportunities, and a personalized support structure to the
                  budding EdTech companies.
                </p>
                <div className="bottom_features">
                  <ul className="p-0">
                    <li>
                      <NavLink>
                        <img src="images/About/WeInspire.svg" />
                        <h5>We Inspire:</h5>
                        <p>
                          Create lasting innovative solutions for education
                          problems
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img src="images/About/WeNurture.svg" />
                        <h5>We Nurture:</h5>
                        <p>
                          Enabling environment for creating a minimum viable
                          product (MVP)
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img src="images/About/WeStabilize.svg" />
                        <h5>We Stabilize:</h5>
                        <p>
                          Help Entrepreneurs find the right
                          Product-Market-Channel fit
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img src="images/About/WeScale.svg" />
                        <h5>We Scale:</h5>
                        <p>
                          Enable ventures identify Product-Market-Channel to
                          grow users
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img src="images/About/WeMonetize.svg" />
                        <h5>We Monetize:</h5>
                        <p>
                          Help the venture stand on its own feet by establishing
                          profitability
                        </p>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Work with us */}
        {/* <section className="ev-work-withUs_sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h2 className="main_head">Work With Us</h2>
                <p>We are looking for you, change maker!</p>
                <p className="para">
                  It’s difference that makes us great. Join a team where
                  diversity of ideas are welcomed, and we are all looking to get
                  better, one moment at a time.
                </p>
                <Link to="/career">
                  <button className="green_btn">
                    Careers
                    <img src="images/Home/white_arrow.svg" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section> */}
      </section>
    </>
  );
}

export default About;
