import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { authenticateLogin } from "services/user.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

export default function Login() {
  const navigate = useNavigate();
  const loginInitialValues = {
    email: "",
    password: "",
  };

  const [login, setLogin] = useState(loginInitialValues);
  const onValueChange = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const loginUser = async (e) => {
    e.preventDefault();

    let response = await authenticateLogin(login);
    console.log("userdata", response);

    if (response.status === 200 && !response.data.error) {
      console.log(response.data.result);
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("user", JSON.stringify(response.data.result));
      navigate("/admin/dashboard");
    } else {
      const notify = () => {};
      toast(
        Boolean(response.data.errors)
          ? Object.values(response.data.errors)[0][0]
          : response.data.Message
      );
      notify();
    }
    if (JSON.parse(localStorage.getItem("isLoggedIn")) === true) {
      console.log("Loginpage");
      return <navigate to="/admin/dashboard" />;
    }
  };

  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blue-950 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png").default + ")",
            }}
          >
            <h1 className="text-center text-white text-3xl mt-20 ">
              Admin Panel{" "}
            </h1>
          </div>

          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col bg-slate-700 min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-6  font-bold">
                      {/* <h1 className="text-2xl text-white">Admin Panel</h1> */}
                    </div>
                    <form onSubmit={loginUser}>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-white text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          name="email"
                          required
                          className=" border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 "
                          placeholder="Email"
                          onChange={(e) => onValueChange(e)}
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-white text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          required
                          name="password"
                          maxlength="15"
                          className=" border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 "
                          placeholder="Password"
                          onChange={(e) => onValueChange(e)}
                        />
                      </div>

                      <div className="text-center mt-6">
                        <button
                          type="submit"
                          className="bg-green-500 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        >
                          Sign In
                        </button>
                      </div>

                      {/* <div className="flex flex-wrap mt-6 relative">
                        <div className="w-1/2">
                          <Link to="/admin/forgot">
                            <h1 className="text-center text-white">
                              Forgot password?
                            </h1>
                          </Link>
                        </div>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
}
