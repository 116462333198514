import axios from "axios";
const { APIURL } = require("global");

const sendMailForContactUs = async (data) => {
  try {
    const URL = APIURL + "AddEnquiry";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const getRole = async () => {
  try {
    const URL = APIURL + "RoleList";
    return await axios.get(`${URL}`);
  } catch (error) {
    return error.response;
  }
};

export { getRole, sendMailForContactUs };
