import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./Health-Insurance.css";
import { Link } from "react-router-dom";
import { dynamicInvestmentMarathon } from "services/configuration.service";
import Timeline from "../Timeline/Timeline";
import Timeline2 from "../Timeline/Timeline2";
import Slider from "react-slick";
import Banner from "../Banner/Banner";

function HealthInsurance() {
  const [investmentMarathonData, setInvestmentMarathonData] = useState([]);
  useEffect(() => {
    dynamicInvestmentMarathon().then((response) => {
      setInvestmentMarathonData(response.data.Result[0]);
    });
  }, []);
  let setting = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let setting2 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Health Policy</h1>
      <p className="mb-4">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac velit
        felis. Morbi suscipit luctus erat, nec faucibus libero dignissim id.
        Vivamus feugiat bibendum mauris, non posuere elit laoreet at. Vestibulum
        sodales, dui eget malesuada tincidunt, urna metus aliquet ex, nec
        volutpat odio lorem id purus.
      </p>
      <p className="mb-4">
        Integer hendrerit lacus vel arcu viverra dictum. Morbi eget nunc
        eleifend, vehicula quam et, cursus eros. Duis efficitur nisl velit, id
        dictum purus viverra nec. Nam sed odio vel metus rhoncus varius sit amet
        eget risus.
      </p>
      <p className="mb-4">
        Praesent pellentesque urna eu efficitur congue. Ut rhoncus hendrerit
        libero, in vestibulum justo. Fusce nec ultrices lorem. Donec tincidunt
        nisi id lacus tincidunt, vel consequat purus tempus. Mauris euismod est
        et nibh efficitur mattis.
      </p>
      <p className="mb-4">
        Fusce interdum convallis ex, nec lacinia ipsum fermentum a. Etiam nec
        commodo sem, at luctus metus. Mauris convallis semper urna id tempor.
        Nam et magna nisl. Donec gravida bibendum ex nec placerat.
      </p>
    </div>
  );
}

export default HealthInsurance;
